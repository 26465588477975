<template>
  <div>
    <div>
      <v-btn color="primary" x-large rounded>Ver preguntas frecuentes </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: "PreguntasFrecuentes",
};
</script>