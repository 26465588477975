<template>
  <div>
    <p :class="['__titleMain']">Seleccione la ruta</p>
    <v-container :class="['__divContainerRuta']">
      <v-row>
        <v-col :class="['__divMainTipo']" cols="12">
          <v-autocomplete
            @change="_getPortEnd()"
            :items="itemPaisBegin"
            item-value="id"
            item-text="puerto"
            v-model="paisBegin"
            label="Seleccione el país y puerto de origen"
          ></v-autocomplete>
        </v-col>

        <v-col :class="['__divMainTipo']" cols="12">
          <v-autocomplete
            :items="itemPaisEnd"
            item-value="id"
            item-text="puerto"
            v-model="paisEnd"
            label="Seleccione el país y puerto de destino"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
    <p :class="['__titleMain']">Agregar Servicios Adicionales</p>
    <v-container :class="['__divContainerServices']">
      <v-row>
        <v-col
          @click="activeService1"
          :class="['__divServices', { __activeServices: service1 }]"
          cols="12"
        >
          <v-row>
            <v-col cols="8">
              <p>Gastos portuarios y de almacenamiento aduanero</p>
            </v-col>
            <v-col cols="4">
              <img
                v-if="!service1"
                src="../../public/assets/img/check-inactive.svg"
                alt=""
              />
              <img
                v-if="service1"
                src="../../public/assets/img/check.svg"
                alt=""
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          :class="['__divServices', { __activeServices: service2 }]"
          cols="12"
        >
          <v-row @click="activeService2">
            <v-col cols="8">
              <p>Cálculo de impuestos y permisos de aduana</p>
            </v-col>
            <v-col cols="4">
              <img
                v-if="!service2"
                src="../../public/assets/img/check-inactive.svg"
                alt=""
              />
              <img
                v-if="service2"
                src="../../public/assets/img/check.svg"
                alt=""
              />
            </v-col>
          </v-row>
          <v-row dense v-if="service2">
            <v-col cols="6">
              <v-autocomplete solo dense></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field dense solo></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="4">
                  <v-autocomplete solo dense></v-autocomplete>
                </v-col>
                <v-col cols="8">
                  <p>¿Ha realizado importaciones previamente?</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          :class="['__divServices', { __activeServices: service3 }]"
          cols="12"
        >
          <v-row @click="activeService3">
            <v-col cols="8">
              <p>Transporte a Domicilio</p>
            </v-col>
            <v-col cols="4">
              <img
                v-if="!service3"
                src="../../public/assets/img/check-inactive.svg"
                alt=""
              />
              <img
                v-if="service3"
                src="../../public/assets/img/check.svg"
                alt=""
              />
            </v-col>
          </v-row>
          <v-row dense v-if="service3">
            <v-col cols="6">
              <v-autocomplete solo dense label="Provincia"></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field dense solo label="Distrito"></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          @click="activeService4"
          :class="['__divServices', { __activeServices: service4 }]"
          cols="12"
        >
          <v-row>
            <v-col cols="8">
              <p>Seguro de mercancia</p>
            </v-col>
            <v-col cols="4">
              <img
                v-if="!service4"
                src="../../public/assets/img/check-inactive.svg"
                alt=""
              />
              <img
                v-if="service4"
                src="../../public/assets/img/check.svg"
                alt=""
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          @click="activeService5"
          :class="['__divServices', { __activeServices: service5 }]"
          cols="12"
        >
          <v-row>
            <v-col cols="8">
              <p>No deseo agregar servicios</p>
            </v-col>
            <v-col cols="4">
              <img
                v-if="!service5"
                src="../../public/assets/img/check-inactive.svg"
                alt=""
              />
              <img
                v-if="service5"
                src="../../public/assets/img/check.svg"
                alt=""
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "TipoContenedor",
  data: () => {
    return {
      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,

      itemPaisBegin: [],
      itemPaisEnd: [],

      paisBegin: "0",
      paisEnd: "0",

      axiosConfig: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      },
    };
  },
  created() {
    this._getPortBegin();
  },
  methods: {
    _getPortBegin() {
      let dataPost = JSON.stringify({
        word: "",
        port: "MARITIMO FCL",
      });
      axios
        .post(
          process.env.VUE_APP_API_URL + "get_find_port_mar_begin",
          dataPost,
          this.axiosConfig
        )
        .then((response) => {
          let dataGen = JSON.parse(JSON.stringify(response.data));

          this.itemPaisBegin = dataGen.data.r_dat;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    _getPortEnd() {
      let dataPost = JSON.stringify({
        word: "",
        port: "MARITIMO FCL",
      });
      axios
        .post(
          process.env.VUE_APP_API_URL + "find_port_to_mar",
          dataPost,
          this.axiosConfig
        )
        .then((response) => {
          let dataGen = JSON.parse(JSON.stringify(response.data));

          this.itemPaisEnd = dataGen.data;
          console.log(response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    routerContainer(tipoContainer) {
      localStorage.setItem("TipodeContenedor", tipoContainer);
      this.$router.push({ name: "TipoContenedor" });
    },
    activeService1() {
      this.service1 = !this.service1;
      this.service5 = false;
    },
    activeService2() {
      this.service2 = !this.service2;
      this.service5 = false;
    },
    activeService3() {
      this.service3 = !this.service3;
      this.service5 = false;
    },
    activeService4() {
      this.service4 = !this.service4;
      this.service5 = false;
    },
    activeService5() {
      this.service1 = false;
      this.service2 = false;
      this.service3 = false;
      this.service4 = false;
      this.service5 = !this.service5;
    },
  },
};
</script>

<style lang="scss" scoped>
.__titleMain {
  font-size: 1.2vw;
  padding: 30px 10px 10px 10px;
  font-weight: 500;

  @media (max-width: 800px) {
    font-size: 4.2vw;
  }
}

.__divContainerRuta {
  padding: 10px 40px;
}

.__divMainTipo {
  padding: 10px;
}

.__activeServices {
  background: #24587b !important;
  color: white;
  font-weight: 400;
}

.__divServices {
  background: #e0e7ec;
  margin-bottom: 5px;
  border-radius: 15px;

  img {
    max-width: 20%;

    float: right;

    @media (max-width: 800px) {
      max-width: 40%;
    }
  }
}
</style>