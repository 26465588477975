<template>
  <div>
    <v-container>
      <v-row :class="['__divConatinerImg']">
        <v-col cols="12" md="4">
          <img src="../../public/assets/img/40std.png" alt="" />
        </v-col>
        <v-col :class="['text-left']" cols="6" md="4">
          <p><b>MEDIDAS INTERNAS DEL CONTENEDOR</b></p>
          <p><b>Largo:</b> 12.03 MTS</p>
          <p><b>Ancho:</b> 2.35 MTS</p>
          <p><b>Alto:</b> 2.38 MTS</p>
        </v-col>
        <v-col :class="['text-left']" cols="6" md="4">
          <p><b>Peso Max. permitido:</b> 26 Toneladas</p>
          <p><b>Vol. Max. Cargable:</b> 67 m<sup>3</sup></p>
        </v-col>
        <v-col :class="['__divContainerNota']" cols="12" md="12">
          <p>
            El peso máximo varía de acuerdo a las normativas establecidas por
            cada naviera
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Informacion40std",
};
</script>

<style lang="scss" scoped>
.__divConatinerImg {
  text-align: center;

  p {
    margin: 0px;
    @media (max-width: 800px) {
      margin: 0px;
      font-size: 3.2vw;
    }
  }

  img {
    width: 80%;

    @media (max-width: 800px) {
      width: 60%;
    }
  }
}

.__divContainerNota {
  background: rgb(185, 7, 7);
  color: white;
  font-weight: 500;
}
</style>