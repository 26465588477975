<template>
  <div>
    <div :class="['__footerMain']">
      <v-btn title="Inicio" elevation="0" :class="['__btnInicio']">
        <img src="../../public/assets/img/menu.svg" alt="" />
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterMain",
};
</script>
<style lang="scss" scoped>
.__footerMain {
  position: fixed;
  bottom: 0px;
  text-align: center;
  background: #24587b;
  color: white;
  width: 800px;
  border-radius: 25px 25px 0px 0px;
  padding: 15px;

  .__btnInicio {
    background: none;
  }

  img {
    max-width: 5%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
}
</style>