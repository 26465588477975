<template>
  <div>
    <div :class="['__divTopMain']">
      <TopbarMain title="Importación Maritima" :ruta="imagen" />
      <div :class="['__divMainContent']">
        <ContenedorCompartido v-if="tipoContenedor == 'LCL'" />
        <ContenedorCompleto v-if="tipoContenedor == 'FCL'" />
      </div>
      <div :class="['__divPreguntas']">
        <PreguntasFrecuentes />
      </div>
      <FooterMain />
    </div>
  </div>
</template>

<script>
import TopbarMain from "@/components/Topbar.vue";
import ContenedorCompartido from "@/components/ContenedorCompartido.vue";
import ContenedorCompleto from "@/components/ContenedorCompleto.vue";
import PreguntasFrecuentes from "@/components/PreguntasFrecuentes.vue";
import FooterMain from "@/components/FooterMain.vue";

import dataImg from "../../../public/assets/img/ship.svg";

export default {
  name: "TipoServicio",
  data: () => {
    return {
      imagen: dataImg,
      tipoContenedor: localStorage.getItem("TipodeContenedor"),
    };
  },
  components: {
    TopbarMain,
    ContenedorCompartido,
    ContenedorCompleto,
    PreguntasFrecuentes,
    FooterMain,
  },
};
</script>

<style lang="scss" scoped>
.__divTopMain {
  max-width: 800px;
  margin: auto;
}

.__divMainContent {
  margin-top: -40px;
  background: white;
  border-radius: 25px 25px 0px 0px;
}

.__divPreguntas {
  background: #f9f9f9;
  border-radius: 25px 25px 0px 0px;
  text-align: center;
  padding: 50px 20px;
  height: 24vh;
  box-shadow: inset 0px 34px 50px -52px rgba(0, 0, 0, 1);
}
</style>