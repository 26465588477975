<template>
  <div>
    <p :class="['__titleMain']">Tipo de Embarque</p>
    <v-container>
      <v-row>
        <v-col :class="['__divMainTipo']" xs="6">
          <div
            @click="routerContainer('FCL')"
            :class="['__divMainInt', '__contenedorActivo']"
          >
            <img src="../../public/assets/img/contenedorfcl.svg" alt="" />
            <p :class="['__titleContenedor']">Contenedor completo</p>
            <p>
              <b>Un solo cliente adquere TODO EL CONTENEDOR</b> y paga el flete
              del mismo
            </p>
          </div>
        </v-col>
        <v-col :class="['__divMainTipo']" xs="6">
          <div @click="routerContainer('LCL')" :class="['__divMainInt']">
            <img src="../../public/assets/img/contenedorlcl.svg" alt="" />
            <p :class="['__titleContenedor']">Contenedor compartido</p>
            <p>
              El cliente <b>paga solo por un espacio</b> dentro del contenedor
              de acuerdo al <b>tamaño y peso</b> de su carga
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "TipoContenedor",
  methods: {
    routerContainer(tipoContainer) {
      localStorage.setItem("TipodeContenedor", tipoContainer);
      this.$router.push({ name: "TipoContenedor" });
    },
  },
};
</script>

<style lang="scss" scoped>
.__titleMain {
  font-size: 1.2vw;
  padding: 30px 10px 10px 10px;
  font-weight: 500;

  @media (max-width: 800px) {
    font-size: 4.2vw;
  }
}

.__divMainTipo {
  padding: 10px;
}

.__contenedorActivo {
  background: #24587b !important;
  color: white;
}

.__divMainInt {
  background: #dbf0ff;
  padding: 15px;
  border-radius: 10px;
  height: 22vh;
  cursor: pointer;

  .__titleContenedor {
    font-weight: 500;
    font-size: 1.2vw;
  }
  p {
    font-size: 0.8vw;
  }

  @media (max-width: 800px) {
    height: 32vh;

    .__titleContenedor {
      font-size: 4.5vw;
    }

    p {
      font-size: 3.5vw;
    }
  }
}
</style>