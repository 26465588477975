<template>
  <div>
    <p :class="['__titleMain']">Contenedor compartido (LCL)</p>
    <v-container>
      <v-row>
        <v-col :class="['__divMainTipo']" xs="12">
          <div :class="['__divContenedor', { __contenedorActivo: lcl1 > 0 }]">
            <v-row>
              <v-col cols="5">
                <p>Contenedor</p>
                <p>20' STANDARD</p>
                <p></p>
              </v-col>
              <v-col cols="3">
                <p>Cantidad</p>
                <v-text-field
                  v-model="lcl1"
                  type="number"
                  min="0"
                  solo
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <div :class="['__divImg', { __contenedorActivo: lcl1 > 0 }]">
                  <img
                    @click="alcl1"
                    v-if="lcl1 <= 0"
                    src="../../public/assets/img/container-inactive.svg"
                    alt=""
                  />
                  <img
                    @click="inalcl1"
                    :class="['__imgCheck']"
                    v-if="lcl1 > 0"
                    src="../../public/assets/img/check.svg"
                    alt=""
                  />
                </div>
              </v-col>
            </v-row>
          </div>
          <div :class="['__divContenedor', { __contenedorActivo: lcl2 > 0 }]">
            <v-row>
              <v-col cols="5">
                <p>Contenedor</p>
                <p>40' STANDARD</p>
              </v-col>
              <v-col cols="3">
                <p>Cantidad</p>
                <v-text-field
                  type="number"
                  v-model="lcl2"
                  solo
                  min="0"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <div :class="['__divImg', { __contenedorActivo: lcl2 > 0 }]">
                  <img
                    @click="alcl2"
                    v-if="lcl2 <= 0"
                    src="../../public/assets/img/container-inactive.svg"
                    alt=""
                  />
                  <img
                    @click="inalcl2"
                    :class="['__imgCheck']"
                    v-if="lcl2 > 0"
                    src="../../public/assets/img/check.svg"
                    alt=""
                  />
                </div>
              </v-col>
            </v-row>
          </div>
          <div :class="['__divContenedor', { __contenedorActivo: lcl3 > 0 }]">
            <v-row>
              <v-col cols="5">
                <p>Contenedor</p>
                <p>40' HC</p>
              </v-col>
              <v-col cols="3">
                <p>Cantidad</p>
                <v-text-field
                  type="number"
                  v-model="lcl3"
                  solo
                  min="0"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <div :class="['__divImg', { __contenedorActivo: lcl3 > 0 }]">
                  <img
                    @click="alcl3"
                    v-if="lcl3 <= 0"
                    src="../../public/assets/img/container-inactive.svg"
                    alt=""
                  />
                  <img
                    @click="inalcl3"
                    :class="['__imgCheck']"
                    v-if="lcl3 > 0"
                    src="../../public/assets/img/check.svg"
                    alt=""
                  />
                </div>
              </v-col>
            </v-row>
          </div>
          <div :class="['__divContenedor', { __contenedorActivo: lcl4 > 0 }]">
            <v-row>
              <v-col cols="5">
                <p>Contenedor</p>
                <p>40' NOR</p>
              </v-col>
              <v-col cols="3">
                <p>Cantidad</p>
                <v-text-field
                  type="number"
                  v-model="lcl4"
                  solo
                  min="0"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <div :class="['__divImg', { __contenedorActivo: lcl4 > 0 }]">
                  <img
                    @click="alcl4"
                    v-if="lcl4 <= 0"
                    src="../../public/assets/img/container-inactive.svg"
                    alt=""
                  />
                  <img
                    @click="inalcl4"
                    :class="['__imgCheck']"
                    v-if="lcl4 > 0"
                    src="../../public/assets/img/check.svg"
                    alt=""
                  />
                </div>
              </v-col>
            </v-row>
          </div>

          <v-btn block color="success" rounded>Continuar</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "ContenedorCompartido",
  data: () => {
    return {
      lcl1: 0,
      lcl2: 0,
      lcl3: 0,
      lcl4: 0,
    };
  },
  methods: {
    inalcl1() {
      this.lcl1 = 0;
    },
    inalcl2() {
      this.lcl2 = 0;
    },
    inalcl3() {
      this.lcl3 = 0;
    },
    inalcl4() {
      this.lcl4 = 0;
    },
    alcl1() {
      this.lcl1 = 1;
    },
    alcl2() {
      this.lcl2 = 1;
    },
    alcl3() {
      this.lcl3 = 1;
    },
    alcl4() {
      this.lcl4 = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.__titleMain {
  font-size: 1.2vw;
  padding: 30px 10px 10px 10px;
  font-weight: 500;

  @media (max-width: 800px) {
    font-size: 4.2vw;
  }
}

.__divMainTipo {
  padding: 10px;

  .__divContenedor {
    background: #24587b24;
    border-radius: 15px;
    padding: 20px;
    height: 95px;
    margin-bottom: 8px;

    .__divImg {
      background: white;
      text-align: center;
      border-radius: 250px;
      width: 60px;
      height: 60px;
      padding-top: 8px;
      float: right;
    }

    img {
      max-width: 70%;
      cursor: pointer;
    }

    .__imgCheck {
      max-width: 90%;
      cursor: pointer;
    }

    p {
      line-height: 5px;
    }
  }
}

.__contenedorActivo {
  background: #24587b !important;
  color: white;
}
</style>