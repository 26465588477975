<template>
  <div>
    <TopMedia />
    <BottomMedia />
  </div>
</template>
<script>
import BottomMedia from "../Home/BottomMedia.vue";
import TopMedia from "../Home/TopMedia.vue";

export default {
  components: {
    BottomMedia,
    TopMedia,
  },
  name: "IndexHome",
};
</script>
