import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home/Index.vue";
import Maritima from "../views/Maritimo/TipoServicio.vue";
import Contenedor from "../views/Maritimo/Contenedor.vue";
import Ruta from "../views/Ruta/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Maritima",
    name: "ImportacionMaritima",
    component: Maritima,
  },
  {
    path: "/Contenedor",
    name: "TipoContenedor",
    component: Contenedor,
  },
  {
    path: "/Ruta",
    name: "Ruta",
    component: Ruta,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
